import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const List = makeShortcode("List");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "curated-material---the-infinite-game",
      "style": {
        "position": "relative"
      }
    }}>{`Curated Material - The Infinite Game`}<a parentName="h1" {...{
        "href": "#curated-material---the-infinite-game",
        "aria-label": "curated material   the infinite game permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <List mdxType="List">
      <Link to="https://www.grantfortheweb.org/" mdxType="Link">
        <h4 {...{
          "id": "grant-for-the-web",
          "style": {
            "position": "relative"
          }
        }}>{`Grant For The Web`}<a parentName="h4" {...{
            "href": "#grant-for-the-web",
            "aria-label": "grant for the web permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`An initiative by Coil and Mozilla, the Grant for the Web is playing the seemingly infinite game of addressing Web 2.0's issues: loss of privacy, centralization of power, and inequalities in online participation.`}</p>
      </Link>
      <Link to="https://fs.blog/2020/02/finite-and-infinite-games/" mdxType="Link">
        <h4 {...{
          "id": "fs-on-finite-and-infinite-games",
          "style": {
            "position": "relative"
          }
        }}>{`FS on Finite and Infinite Games`}<a parentName="h4" {...{
            "href": "#fs-on-finite-and-infinite-games",
            "aria-label": "fs on finite and infinite games permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`Farnam Street's take on how to apply James Carse's work in our daily lives. Fs.blog helps us see the world better using these and other mental models, and is a great resource for Kernel fellows in our journey of continuous learning.`}</p>
      </Link>
      <Link to="https://stratechery.com/" mdxType="Link">
        <h4 {...{
          "id": "stratechery",
          "style": {
            "position": "relative"
          }
        }}>{`Stratechery`}<a parentName="h4" {...{
            "href": "#stratechery",
            "aria-label": "stratechery permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`Another indispensable resource for Web 3 builders. Ben Thompson's writings allow us to see the bigger picture of what's happening in tech and business, reminding us that the work we do never takes place in a vacuum.`}</p>
      </Link>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      